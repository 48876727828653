

















import './scss/BaseViewToggler.scss';
import './scss/BaseViewTogglerAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'BaseViewToggler',

    components: {
        'BaseButton': () => import('@components/buttons/BaseButton.vue'),
    },
})
export default class BaseViewToggler extends Vue {
    @Prop({
        validator(views: any[]) {
            return views.every((view) => view.label !== undefined);
        }
    }) views!: any[];
    @Prop( { default: () => ({}) } ) currentViewMode!: any;

    emitViewChange(view: any) {
        this.$emit('view-change', view);
        this.currentView = view;
    }

    currentView: any = this.currentViewMode;

    created() {
        if (this.views.length && Object.keys(this.currentViewMode).length) this.emitViewChange(this.currentViewMode)
        else this.emitViewChange(this.views[0])
    }
}
